var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"drop-file--modal drop-file",on:{"close":_vm.checkClose}},[_c('template',{slot:"header"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'profile_SelectFile',
                      ])}}}),_vm._v(" "+_vm._s(_vm.$t('profile_SelectFile.localization_value.value'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"drop-file__content"},[_c('div',{staticClass:"drop-file__row custom-row"},[_c('div',{staticClass:"drop-file__col custom-col custom-col--50 custom-col--xs-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'profile_number',
                      ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.validation.number},attrs:{"error":_vm.validation.number,"errorTxt":_vm.serverError ? _vm.validationTxt.number : _vm.$t(`${_vm.validationTranslate.number}.localization_value.value`),"label":_vm.$t('profile_number.localization_value.value'),"type":'text'},on:{"onEnter":_vm.submitForm},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})],1),_c('div',{staticClass:"drop-file__col custom-col custom-col--50 custom-col--xs-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'profile_DocumentType',
                      ])}}}),_c('DefaultSelect',{class:{'ui-no-valid': _vm.validation.selectedType},attrs:{"options":_vm.optionType,"error":_vm.validation.selectedType,"errorTxt":_vm.serverError ? _vm.validationTxt.selectedType : _vm.$t(`${_vm.validationTranslate.selectedType}.localization_value.value`),"label":_vm.$t('profile_DocumentType.localization_value.value'),"optionsLabel":'value',"selected":_vm.selectedType.value},on:{"change":_vm.changeType}})],1)]),_c('DropZone',{attrs:{"parentFiles":_vm.files,"maxSize":'3'},on:{"changeImg":_vm.changeImg}})],1)]),_c('template',{slot:"footer"},[_c('div',{staticClass:"drop-file__btn d-flex justify-content-between align-center"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'common_cancel',
                      ])}}}),_c('span',{staticClass:"site-link site-link--alt drop-file__btn-i",on:{"click":_vm.checkClose}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")])]),_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'profile_uploadDocuments',
                      ])}}}),_c('MainButton',{staticClass:"drop-file__btn-i wfc",class:{'disabled-btn' : _vm.$store.getters.getProfileBtn},attrs:{"value":_vm.$t('profile_uploadDocuments.localization_value.value')},nativeOn:{"click":function($event){return _vm.submitForm.apply(null, arguments)}}})],1)])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }