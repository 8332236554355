<template>
  <div class="order-create__col custom-col">
    <div class="site-table-wrap profile-shops-table"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
        'shops_Logo',
        'shops_shopName',
        'shops_created',
        'shops_linkToTheShop',
      ])"></div>

      <template v-if="$store.getters.getShops.length > 0">
        <table class="site-table"
               v-if="!isMobileFunc()"
        >
          <thead>
          <tr>
            <th>{{$t('shops_Logo.localization_value.value')}}</th>
            <th>{{$t('shops_shopName.localization_value.value')}}</th>
            <th>{{$t('shops_created.localization_value.value')}}</th>
            <th>{{$t('shops_linkToTheShop.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getShops"
              :key="index">
            <td align="center">
              <img
                      v-if="_.has(defaultShopsTypes, item.shop_type.name)"
                      :src="defaultShopsTypes[item.shop_type.name].ico" alt="ico"
                      style="vertical-align: middle;">
              <img
                  v-else-if="defaultOtherShopsTypes.ukrainiantreasures.type === item.shop_type.name"
                  :src="defaultOtherShopsTypes.ukrainiantreasures.ico" alt="ico"
                  style="vertical-align: middle;">
              <img v-else
                   src="/img/common/documents-icon.svg"
                   alt="ico"
                   style="vertical-align: middle;">
            </td>
            <td><div class="shop-name">{{ item.shop_name }}</div></td>
            <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>
            <td><div class="shop-name"> {{ item.shop_param.shop_link }} </div></td>
          </tr>
          </tbody>
        </table>

        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-for="(item, index) in $store.getters.getShops"
                 :key="index">
              <div class="site-table-mobile__item  bg--main-bg">
                <div class="site-table-mobile__head d-flex align-center">
                  <div class="site-table-mobile__head-logo mr-2">
                    <img
                            v-if="_.has(defaultShopsTypes, item.shop_type.name)"
                            :src="defaultShopsTypes[item.shop_type.name].ico" alt="ico"
                            style="vertical-align: middle;">
                    <img
                            v-else-if="defaultOtherShopsTypes.ukrainiantreasures.type === item.shop_type.name"
                            :src="defaultOtherShopsTypes.ukrainiantreasures.ico" alt="ico"
                            style="vertical-align: middle;">
                    <img v-else
                         src="/img/common/documents-icon.svg"
                         alt="ico"
                         style="vertical-align: middle;">
                  </div>
                  {{ item.shop_name }}
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{$t('shops_created.localization_value.value')}}
                    <span>
                   {{ item.created_at | moment("DD MMM, YYYY") }}
                  </span>
                  </div>
                  <div class="site-table-mobile__piece custom-col">
                    {{$t('shops_linkToTheShop.localization_value.value')}}
                    <span>
											{{ item.shop_param.shop_link }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </template>

    </div>
  </div>
</template>

<script>
  import {mixinDetictingMobile} from "../../../../../mixins/mobileFunctions";

  export default {
    name: "TableShops",

    mixins: [mixinDetictingMobile],

    components: {},

    data() {
      return {
        defaultShopsTypes: this.$store.getters.getHeaderDefaultShops,
        defaultOtherShopsTypes: this.$store.getters.getDefaultShopsTypes,
      }
    },

    mounted() {
      const query = new this.Query();
      let url = '?';
      let myQuery = query
        .for('posts')

      url = url + myQuery.limit(100000).url().split('?')[1]

      this.$store.dispatch('fetchShopsWithoutPermission', url).then(() => {
        // skip get next page
        this.$store.commit('setNextShopPage', false)
      })
    },

    methods: {},

  }
</script>

<style lang="scss">
  @import "../../../../../scss/mixins/mixins";

  .profile-shops-table.site-table-wrap {
    width: 100%;

    .shop-name{
      word-break: break-word;
      max-width: 500px;
    }


    @include for-1120 {
      overflow: auto;
    }

    .site-table tbody tr {
      height: 50px;
    }

    .site-table tbody td {
      padding-top: 6px;
      padding-bottom: 6px;
    }

    .site-table thead th {
      color: #ffffff;
      padding-top: 14px;
      padding-bottom: 14px;
    }

    .site-table {
      margin-top: -10px;

      @include for-1120 {
        min-width: 800px;
        margin: 0;
      }
    }
  }
</style>
