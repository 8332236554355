<template>
  <div class="add-features">
    <div class="add-features__inner">
      <div class="add-features__list custom-row">
        <div class="custom-col custom-col--50 custom-col--xs-100">
          <div class="add-features__item">
            <div class="add-features__item-content">

              <div class="add-features__item-row">
                <div class="add-features__item-title admin-edit-item">
                  <span class="admin-edit" @click="editTranslate(['profile_googleAuthentication'])"></span>
                  <div class="add-features__item-ico">
                    <img src="/img/profile-group/google-authentication-profile-icon.png" alt="ico">
                  </div>
                  {{ $t('profile_googleAuthentication.localization_value.value') }}
                </div>
                <div class="add-features__item-switch">
<!--                  <SwitchCheckbox :value="-->
<!--									$store.getters.getUserProfile.auth_type === null ||-->
<!--									$store.getters.getUserProfile.auth_type === TWO_FACTOR_AUTH_TYPE.GOOGLE ? true : false"-->
<!--                    :noHover="true"-->
<!--                  />-->
                </div>
              </div>

              <div class="add-features__item-row admin-edit-item">
                <span class="admin-edit" @click="editTranslate(['profile_viewUrgent'])"></span>
								<span class="site-link"
                      v-if="$store.getters.getUserProfile.auth_type === TWO_FACTOR_AUTH_TYPE.GOOGLE"
                      @click="showUrgentCodesPopup"
                >{{ $t('profile_viewUrgent.localization_value.value') }}</span>
                <span class="site-link"
                      @click="showQRPopup"
                      v-else
                >{{ $t('profile_viewQR.localization_value.value') }}</span>
              </div>

            </div>
          </div>
        </div>
        <div class="custom-col custom-col--50 custom-col--xs-100">
          <!--<div class="add-features__item">-->
            <!--<div class="add-features__item-content">-->
              <!--<div class="add-features__item-row">-->
                <!--<div class="add-features__item-title admin-edit-item">-->
                  <!--<span class="admin-edit" @click="editTranslate(['profile_easyAccount'])"></span>-->
                  <!--<div class="add-features__item-ico">-->
                    <!--<img src="/img/profile-group/easy-account-icon.svg" alt="ico">-->
                  <!--</div>-->
                  <!--{{ $t('profile_easyAccount.localization_value.value') }}-->
                <!--</div>-->

                <!--<div class="add-features__item-switch">-->
                  <!--<SwitchCheckbox :value="-->
									<!--$store.getters.getUserProfile.functionality_type === null ||-->
									<!--$store.getters.getUserProfile.functionality_type === FUNCTIONALITY.FULL ? false: true"-->
                                  <!--@input="changeFunctionality"/>-->
                <!--</div>-->
              <!--</div>-->
              <!--<div class="add-features__item-row admin-edit-item">-->

                <!--<span class="admin-edit" @click="editTranslate(['profile_whatIsEA'])"></span>-->
                <!--<span class="site-link">-->
                  <!--{{ $t('profile_whatIsEA.localization_value.value') }}-->
                <!--</span>-->
              <!--</div>-->
            <!--</div>-->
          <!--</div>-->
        </div>
      </div>
    </div>


    <UrgentCodesPopup
            @close="closeUrgentCodesPopup"
            v-if="isModalUrgentCodesPopup"
    />

    <QRPopup
            @close="closeQRPopup"
            v-if="isModalQRPopup"
            class="full-modal"
    />
  </div>
</template>

<script>
  // import SwitchCheckbox from "../../../../../UI/checkboxes/SwitchCheckbox/SwitchCheckbox";
  import QRPopup from "../../popups/QRPopup/QRPopup";
  import UrgentCodesPopup from "../../../popups/UrgentCodesPopup/UrgentCodesPopup";

  export default {
    name: "AdditionalFeatures",

    components: {
      // SwitchCheckbox,
      QRPopup,
      UrgentCodesPopup,
    },

    data() {
      return {
        googleAuthentication: false,
        isModalQRPopup: false,
        isModalUrgentCodesPopup: false,

        FUNCTIONALITY: {
          FULL: 'full',
          EASY: 'easy',
        },

        TWO_FACTOR_AUTH_TYPE: {
          EMAIL: 'email',
          GOOGLE: 'google',
        },
      }
    },

    methods: {
      showQRPopup() {
        this.isModalQRPopup = true
      },

      closeQRPopup() {
        this.isModalQRPopup = false
      },

      showUrgentCodesPopup() {
        this.isModalUrgentCodesPopup = true
      },

      closeUrgentCodesPopup() {
        this.isModalUrgentCodesPopup = false
      },

      changeFunctionality(checked) {
        this.$store.dispatch('changeProfileFunctionality',
          {
            id: this.$store.getters.getUserProfile.id,
            data: {
              functionality_type:
                checked ? this.FUNCTIONALITY.EASY : this.FUNCTIONALITY.FULL
            }
          }).then(() => {
          let user = this.$store.getters.getUserProfile
          user.functionality_type = checked ? this.FUNCTIONALITY.EASY : this.FUNCTIONALITY.FULL
          this.$store.commit('setUserProfile', {userProfile: user})
        })
      },

      changeTwoFactorAuthType(checked) {
        this.$store.dispatch('changeTwoFactorAuthType',
          {
            id: this.$store.getters.getUserProfile.id,
            data: {
              'auth_type':
                checked ? this.TWO_FACTOR_AUTH_TYPE.GOOGLE : this.TWO_FACTOR_AUTH_TYPE.EMAIL
            }
          })
      },
    }
  }
</script>

<style lang="scss">
  @import "../../../../../../scss/colors";
  @import "../../../../../../scss/mixins/mixins";

  .add-features {
    &__inner {

    }

    &__list {

    }

    &__item {
      height: 100%;
    }

    &__item-content {
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 5px;
      padding: 14px 48px;
      height: 100%;

      @include for-768 {
        padding: 15px;
      }
    }

    &__item-row {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;

      &:after {
        content: '';
        background: $mainBg;
        height: 1px;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
      }

      &:last-child:after {
        display: none;
      }
    }

    &__item-title {

      @include for-768 {
        padding-left: 24px;
      }
    }

    &__item-ico {
      position: absolute;
      top: 8px;
      left: -24px;
      width: 20px;
      height: 20px;

      @include for-768 {
        left: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__item-switch {

    }

  }

</style>
