<template>
  <div class="page-content">
    <template v-if="$store.getters.getCurrentUserRights.additionalPermissions && $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]">
      <ProfileAdmin/>
    </template>

    <template v-else>
      <Breadcrumb/>
      <SearchEngine
          v-if="isAdmin"
      />

      <Profile :largeAvatar="largeAvatar" :documentsImage="documentsImage" />
    </template>


  </div>

</template>

<script>
  import Breadcrumb from "../../templates/Breadcrumb/Breadcrumb.vue";
  import Profile from "./components/Profile/Profile";
  import {
    GET_DOCUMENTS_IMAGE,
    GET_LARGE_AVATAR,
    // GET_SMALL_AVATAR,
    SET_AUTORIZE_DATA_AND_AVATAR
  } from "../../../services/commonFunctions";
  import ProfileAdmin from "./ProfileAdmin/ProfileAdmin";
  import {checkAccess} from "@/mixins/userCredentionalMixins/userCredentional";
  import SearchEngine from "../../coreComponents/SearchEngine/SearchEngine";


  export default {
    name: "ProfileModule",

    components: {
      SearchEngine,
      Breadcrumb,
      Profile,
      ProfileAdmin,
    },

    mixins: [checkAccess],

    watch: {
      loadUserRoleAndAuthorizedData(newVal){
        if(newVal){
          this.loadProfileData()
        }
      },
    },

    data() {
      return {
        largeAvatar: '',
        documentsImage: [],
      }
    },

    mounted() {


      if(this.loadUserRoleAndAuthorizedData){
        this.loadProfileData()
      }

      // // CHECK LOADING USER DATA and GET BIG AVATAR
      // if(!Object.hasOwnProperty.call(this.$store.getters.getUserProfile, 'id')){
      //   let interval = setInterval(() => {
      //
      //     if(Object.hasOwnProperty.call(this.$store.getters.getUserProfile, 'id')){
      //
      //       SET_AUTORIZE_DATA_AND_AVATAR(this.$store).then((response) => {
      //         if(Object.hasOwnProperty.call(response, 'response') && response.response.status === this.$store.getters.GET_ERRORS.FORBIDDEN_CODE){
      //           this.goToTwoFactorUathPage()
      //           return
      //         }
      //
      //         GET_LARGE_AVATAR(this.$store).then((response) => {
      //           this.largeAvatar = response
      //           localStorage.setItem('bigAvatar', response)
      //         })
      //
      //         this.documentsImage = GET_DOCUMENTS_IMAGE(this.$store)
      //       })
      //       clearInterval(interval)
      //     }
      //     if(interval > 10000){
      //       clearInterval(interval)
      //     }
      //   }, 50)
      // } else {
      //   SET_AUTORIZE_DATA_AND_AVATAR(this.$store).then((response) => {
      //
      //     if(Object.hasOwnProperty.call(response, 'response') && response.response.status === this.$store.getters.GET_ERRORS.FORBIDDEN_CODE){
      //       this.goToTwoFactorUathPage()
      //       return
      //     }
      //
      //     GET_LARGE_AVATAR(this.$store).then((response) => {
      //       this.largeAvatar = response
      //       localStorage.setItem('bigAvatar', response)
      //     })
      //     this.documentsImage = GET_DOCUMENTS_IMAGE(this.$store)
      //   })
      // }

    },

    methods: {

      loadProfileData(){
        SET_AUTORIZE_DATA_AND_AVATAR(this.$store).then((response) => {
          if(Object.hasOwnProperty.call(response, 'response') && response.response.status === this.$store.getters.GET_ERRORS.FORBIDDEN_CODE){
            this.goToTwoFactorUathPage()
            return
          }

          GET_LARGE_AVATAR(this.$store).then((response) => {
            this.largeAvatar = response
            localStorage.setItem('bigAvatar', response)
          })

          this.documentsImage = GET_DOCUMENTS_IMAGE(this.$store)
        }).catch(error => this.createErrorLog(error))
      },

      goToTwoFactorUathPage() {
        if(this.$store.getters.getUserProfile.auth_type === 'email') {
          this.$router.push(this.$store.getters.GET_PATHS.authenticationEmail + `/mainSettingsMyProfile`)
        } else {
          this.$router.push(this.$store.getters.GET_PATHS.authenticationGoogle + `/mainSettingsMyProfile`)
        }
      },
    }

  }
</script>

<style lang="scss">

</style>
