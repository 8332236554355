export const boolToStringFunc = {
  methods: {
    boolToStringFunc(value, arraySearchValues, ifTrue, ifFalse) {
      let flag = false
      arraySearchValues.map((item) => {
        if(value === item){
          flag = true
        }
      })
      return flag ? ifTrue : ifFalse
    },

    redirectToNotFound(value) {
      if(!value) {
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }
    }
  }
}
