<template>
  <modal
          @close="$emit('close')"
          class=" custom-popup-modal custom-popup"
  >
    <template slot="body">
      <div class="qr-code">
        <span v-html="qrCode"></span>
      </div>
      <div style="max-width: 500px; margin: 0 auto; padding-bottom: 20px">
        <WhiteInput
                v-model="code"
                v-bind:class="{'ui-no-valid': validation.code}"
                :error="validation.code"
                :errorTxt="validationTxt.code"
                :label="$t('profile_QrCode.localization_value.value')"
                @onEnter="submitForm"
        />

        <div class="auth-form__btn" style="margin-top: 20px;">
          <div class="auth-form__btn-login">
            <FullButton
                    @click.native="submitForm"
                    :value="$t('common_submit.localization_value.value')"
            />
          </div>
        </div>
      </div>


    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../../commonModals/Modal";
  import WhiteInput from "../../../../../UI/inputs/WhiteInput/WhiteInput";
  import FullButton from "../../../../../UI/buttons/FullButtons/FullButton";

  export default {
    name: "QRPopup",

    components: {
      Modal,
      WhiteInput,
      FullButton
    },

    data() {
      return {
        qrCode: "",
        code: "",

        validation: {
          code: false,
        },

        validationTxt: {
          code: false,
        }
      }
    },

    mounted() {
      this.$store.dispatch('twoFactorGoogle').then((response) => {
        // console.log(response.data.data.qrCode);
        this.qrCode = response.data.data.qrCode
      })
    },

    methods: {
      submitForm(){
        this.$store.dispatch('twoFactorGoogleActivate', {code: this.code}).then((response) => {
          if(Object.hasOwnProperty.call(response, 'response') && response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            this.serverError = true
            this.validation.code = true
            this.validationTxt.code = response.response.data.errors.code[0]
            return
          }

          if(response.data.data.auth_type === 'google'){
            let user = this.$store.getters.getUserProfile
            user.auth_type = 'google'
            this.$store.commit('setUserProfile', {userProfile: user})
            this.$emit('close')
          }

        })
      }
    },


  }
</script>

<style lang="scss">
  @import "../../../../../../scss/mixins/mixins";

  .qr-code{
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    @include for-768{
      height: 400px;
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
</style>
