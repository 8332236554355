<template>
  <div class="photo-block">
		<div class="photo-block__inner">
			<img class="photo-block__photo"
					 v-if="photo.length > 0"
					 :src="photo"
					 alt="photo"
			>
			<img class="photo-block__photo"
					 v-else
					 src="/img/profile-group/profile-photo-default.svg"
					 alt="photo"
			>
			<label class="photo-block__label">
				<input type="file" @change="uploadImage" accept="image/png,image/jpeg">
				<span class="photo-block__txt site-link site-link--alt">
					+ {{$t('profile_choosePhoto.localization_value.value')}}
				</span>
			</label>

			<br><br><br>
			<div class="default-input-wrap__error error-field" v-if="photoError">
				{{photoErrorTxt}}
			</div>

		</div>

	</div>
</template>

<script>
	// import {REFRESH_AVATAR} from "../../../../../services/commonFunctions";

	import {MAX_SIZE_AVATAR_IMG} from "../../../../../staticData/staticVariables";

	export default {
    name: "Photo",

		data() {
    	return {
    		photo: '',
				photoError: false,
				photoErrorTxt: '',
			}
		},

		props: ['largeAvatar'],

		watch: {
			largeAvatar: function (val) {
				this.photo = val
			}
		},

		mounted() {
    	if(this.largeAvatar === '' && localStorage.getItem('bigAvatar') !== null){
				this.photo = localStorage.getItem('bigAvatar') !== 'false' ? localStorage.getItem('bigAvatar') : ''
			} else {
				this.photo = this.largeAvatar ? this.largeAvatar : ''
			}

		},

		methods:{
			uploadImage(e){
				if (e.target.files.length > 0 && e.target.files[0].size / 1000 > MAX_SIZE_AVATAR_IMG) {
					this.photoError = true
					this.photoErrorTxt = this.$t('profile_maxFileSize.localization_value.value')
					return
				}
				const reader = new FormData();
				reader.append("file", e.target.files[0]);

				this.$store.dispatch('changeAvatar', {id: this.$store.getters.getUserProfile.id, data: reader}).then((response) => {

					if(this._.has(response, 'bigImageEntity') && response.status === true){
						this.photo = `data:image/${response.bigImageEntity.extension};base64,${response.bigImageEntity.file}`
						localStorage.setItem('bigAvatar', this.photo)

						let imgSmall = `data:image/${response.smallImageEntity.extension};base64,`
										+ response.smallImageEntity.file
						this.$store.commit('setAvatarSmall', imgSmall)
						localStorage.setItem('avatar', imgSmall)
						this.photoError = false
					} else if(response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
						this.photoError = true
						this.photoErrorTxt = response.response.data.errors.file[0]
					}


				}).catch(error => this.createErrorLog(error))
			}
		}

  }
</script>

<style lang="scss">
	@import "../../../../../scss/mixins/mixins";

	.photo-block{
		padding-bottom: 50px;

		&__inner{
			position: relative;
			background: white;
			/*padding: 16px;*/
			width: 100%;
			height: 444px;
			border-radius: 5px;

			@include for-1800{
				/*height: 320px;*/
				height: 320px
			}
		}
		&__photo{
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 5px;
		}
		&__label{
			input[type=file]{
				display: none;
			}
		}
		&__txt{
/*			position: absolute;
			top: calc(100% + 15px);
			left: 50%;
			transform: translateX(-50%);
			z-index: 10;*/

			text-align: center;
			display: flex;
			justify-content: center;
			padding: 5px;
		}

		.default-input-wrap__error{
			/*width: 100%;
			text-align: center;
			bottom: -60px;*/

			bottom: 0;
			background: white;
			padding: 5px;
			top: auto;
			text-align: center;
			text-overflow: initial;
			white-space: normal;
			box-shadow: 0 -3px 10px -10px black;
		}

	}

</style>
