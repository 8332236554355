<template>
  <modal
          @close="$emit('close')"
          class="address-editing-modal address-editing"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['profile_personalInformationEditing'])"></div>
        {{$t(`profile_personalInformationEditing.localization_value.value`)}}
      </div>
    </template>
    <template slot="body">
      <div class="address-editing__content">
        <div class="section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['registration_personalInformation'])"></div>
          {{ $t('registration_personalInformation.localization_value.value') }}
        </div>

        <div class="address-editing__row custom-row" v-if="Object.keys(data).length > 0">
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_name'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': validation.first_name}"
                    :error="validation.first_name"
                    :errorTxt="serverError ? validationTxt.first_name : $t(`${validationTranslate.first_name}.localization_value.value`)"
                    :label="$t('common_name.localization_value.value')"
                    :type="'text'"
                    v-model="data.user_personal_contact.first_name"
            />
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_surName'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': validation.last_name}"
                    :error="validation.last_name"
                    :errorTxt="serverError ? validationTxt.last_name : $t(`${validationTranslate.last_name}.localization_value.value`)"
                    :label="$t('common_surName.localization_value.value')"
                    :type="'text'"
                    v-model="data.user_personal_contact.last_name"
            />
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['profile_fullName'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': validation.cyrillic_full_name}"
                    :error="validation.cyrillic_full_name"
                    :errorTxt="serverError ? validationTxt.cyrillic_full_name : $t(`${validationTranslate.cyrillic_full_name}.localization_value.value`)"
                    :label="$t('profile_fullName.localization_value.value')"
                    :type="'text'"
                    v-model="data.user_personal_contact.cyrillic_full_name"
            />
          </div>
          <!--<div class="address-editing__col custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;xs-100">-->
            <!--<DefaultInput-->
                    <!--v-bind:class="{'ui-no-valid': validation.phone}"-->
                    <!--:error="validation.phone"-->
                    <!--:errorTxt="serverError ? validationTxt.phone : $t(`${validationTranslate.phone}.localization_value.value`)"-->
                    <!--:label="$t('common_phone.localization_value.value')"-->
                    <!--:type="'text'"-->
                    <!--v-model="data.user_personal_contact.phone"-->
            <!--/>-->
          <!--</div>-->
          <!--<div class="address-editing__col custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;xs-100">-->
          <!--<DefaultInput-->
          <!--v-bind:class="{'ui-no-valid': validation.email}"-->
          <!--:error="validation.email"-->
          <!--:errorTxt="serverError ? validationTxt.email : $t(`${validationTranslate.email}.localization_value.value`)"-->
          <!--:label="'Email'"-->
          <!--:type="'text'"-->
          <!--v-model="data.user_personal_contact.email"-->
          <!--/>-->
          <!--</div>-->
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['profile_companyName'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': validation.company_name}"
                    :error="validation.company_name"
                    :errorTxt="serverError ? validationTxt.company_name : $t(`${validationTranslate.company_name}.localization_value.value`)"
                    :label="$t('profile_companyName.localization_value.value')"
                    :type="'text'"
                    v-model="data.user_personal_contact.company_name"
            />
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['profile_aditionalEmail'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': validation.additional_email}"
                    :error="validation.additional_email"
                    :errorTxt="serverError ? validationTxt.additional_email : $t(`${validationTranslate.additional_email}.localization_value.value`)"
                    :label="$t('profile_aditionalEmail.localization_value.value')"
                    :type="'text'"
                    v-model="data.user_personal_contact.additional_email"
            />
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['profile_vat'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': validation.vat_number}"
                    :error="validation.vat_number"
                    :errorTxt="serverError ? validationTxt.vat_number : $t(`${validationTranslate.vat_number}.localization_value.value`)"
                    :label="$t('profile_vat.localization_value.value')"
                    :type="'text'"
                    v-model="data.vat_number"
            />
          </div>

          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['profile_birthday'])"></div>
            <DatePickerDefault
                    :label="$t('profile_birthday.localization_value.value')"
                    :placeholder="true"
                    v-bind:class="{'ui-no-valid': validation.birthday}"
                    :errorTxt="$t(`${validationTranslate.birthday}.localization_value.value`)"
                    :error="validation.birthday"
                    v-model="data.user_personal_contact.birthday"
            >
              <template slot="body">
                <date-picker
                        v-model="data.user_personal_contact.birthday"
                        ref="date"
                        valueType="format"
                        :popup-class="'birthday-picker'"
                        :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                        :format="'YYYY-MM-DD'"
                        :placeholder="'YYYY-MM-DD'"
                ></date-picker>
              </template>
            </DatePickerDefault>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="address-editing__btn"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel','common_update'])"></div>
        <span
                class="site-link site-link--alt address-editing__btn-i"
                @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
                :value="$t('common_update.localization_value.value')"
                class="address-editing__btn-i"
                v-on:click.native="submitForm"
                v-bind:class="{'disabled-btn' : $store.getters.getProfileBtn}"
        />
      </div>
    </template>
  </modal>
</template>


<script>
  import Modal from "../../../../commonModals/Modal";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import {validation} from "../../../../../services/validation";
  import {checkAccess} from "../../../../../mixins/userCredentionalMixins/userCredentional";
  import DatePickerDefault from "../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'

  export default {
    name: "PersonalEditing",
    components: {
      Modal,
      DefaultInput,
      MainButton,
      DatePickerDefault,
      DatePicker,
    },

    mixins: [checkAccess],

    data() {
      return {
        data: {},


        validation: {
          first_name: false,
          last_name: false,
          phone: false,
          email: false,
          company_name: false,
          additional_email: false,
          cyrillic_full_name: false,
          vat_number: false,
          birthday: false,
        },

        validationTranslate: {
          first_name: 'default',
          last_name:'default',
          phone:'default',
          email: 'default',
          company_name: 'default',
          additional_email: 'default',
          cyrillic_full_name: 'default',
          vat_number: 'default',
          birthday: 'default',
        },

        validationTxt: {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          company_name: '',
          additional_email: '',
          cyrillic_full_name: '',
          vat_number: '',
          birthday: '',
        },

        serverError: false,

        options: [{}],
      }
    },

    mounted() {
      this.data = this._.cloneDeep(this.$store.getters.getUserProfile);

      Object.keys(this.data.user_personal_contact).map(item => {
        if(this.data.user_personal_contact[item] === null){
          this.data.user_personal_contact[item] = ''
        }
      })
      if(this.data.vat_number === null){
        this.data.vat_number = ''
      }
    },

    methods:{

      submitForm(e) {
        e.preventDefault();
        let validationItems = {
          first_name: this.data.user_personal_contact.first_name,
          last_name: this.data.user_personal_contact.last_name,
          phone: this.data.user_personal_contact.phone,
          email: this.data.user_personal_contact.email,
          company_name: this.data.user_personal_contact.company_name,
          additional_email: this.data.user_personal_contact.additional_email,
          cyrillic_full_name: this.data.user_personal_contact.cyrillic_full_name,
          vat_number: this.data.vat_number,
          birthday: this.data.user_personal_contact.birthday,
        }


        let validationOptions = {
          first_name: {
            type: [
              'latin',
              'empty',
            ]
          },
          last_name: {
            type: [
              'latin',
              'empty',
            ]
          },
          phone: {
            type: [
              'phone',
              'empty',
            ]
          },
          email: {
            type: [
              'latin',
              'email',
              'empty',
            ]
          },
          company_name: {
            type: [
              'latin',
            ]
          },
          // birthday: {
          //   type: [
          //     'empty',
          //   ]
          // },
        };

        let validate = validation(validationItems, validationOptions);

        this.validation = validate.validation;
        this.validationTranslate = validate.validationError;


        if(validate.isValidate) {
          this.serverError = false;

          let data = {
            email: this.data.user_personal_contact.email,
            vat_number: this.data.vat_number,
            contacts: {
              first_name: this.data.user_personal_contact.first_name,
              last_name: this.data.user_personal_contact.last_name,
              phone: this.data.user_personal_contact.phone,
              // email: this.data.user_personal_contact.email,
              company_name: this.data.user_personal_contact.company_name,
              additional_email: this.data.user_personal_contact.additional_email,
              cyrillic_full_name: this.data.user_personal_contact.cyrillic_full_name,
              birthday: this.data.user_personal_contact.birthday ? this.$moment(this.data.user_personal_contact.birthday).format('YYYY-MM-DD') : null,
            }
          };

          this.$store.dispatch('changeProfilePersonal', {id: this.data.id, data: data}).then((response) => {

            if(this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){
              this.$emit('close');
              this.openNotify('success', 'common_notificationInformationChanged')
            } else if(response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
              let errors = response.response.data.errors;

              this.serverError = true;
              errors['contacts.first_name'] ? ( this.validation.first_name = true,    this.validationTxt.first_name = errors['contacts.first_name'][0] )  : false;
              errors['contacts.last_name'] ? ( this.validation.last_name = true,    this.validationTxt.last_name = errors['contacts.last_name'][0] )  : false;
              errors['contacts.phone'] ? ( this.validation.phone = true,    this.validationTxt.phone = errors['contacts.phone'][0] )  : false;
              // errors['contacts.email'] ? ( this.validation.email = true,    this.validationTxt.email = errors['contacts.email'][0] )  : false;
              errors['contacts.company_name'] ? ( this.validation.company_name = true,    this.validationTxt.company_name = errors['contacts.company_name'][0] )  : false;
              errors['contacts.additional_email'] ? ( this.validation.additional_email = true,    this.validationTxt.additional_email = errors['contacts.additional_email'][0] )  : false;
              errors['contacts.cyrillic_full_name'] ? ( this.validation.cyrillic_full_name = true,    this.validationTxt.cyrillic_full_name = errors['contacts.cyrillic_full_name'][0] )  : false;
              errors['vat_number'] ? ( this.validation.vat_number = true,    this.validationTxt.vat_number = errors['vat_number'][0] )  : false;
              errors['birthday'] ? ( this.validation.birthday = true,    this.validationTxt.birthday = errors['birthday'][0] )  : false;
            } else if(response.response.status === this.$store.getters.GET_ERRORS.FORBIDDEN_CODE) {
              this.checkAccess2faAt()
            }

          });

        }

      },
    }
  }

</script>

<style lang="scss">
  @import "../../../../../scss/colors";
  @import "../../../../../scss/mixins/mixins";


  .birthday-picker .mx-btn-icon-double-left, .mx-btn-icon-double-right {
    display: block !important;

    .mx-icon-double-left:before, .mx-icon-double-left:after {
      transform: rotate(-45deg) scale(1) !important;
      border-left: 2px solid #8F7A61 !important;
      border-top: 2px solid #8F7A61 !important;
    }

    .mx-icon-double-right:before, .mx-icon-double-right:after {
      transform: rotate(135deg) scale(1) !important;
      border-left: 2px solid #8F7A61 !important;
      border-top: 2px solid #8F7A61 !important;
    }
  }


  .address-editing {

    .modal-component__inner {
      max-width: 740px;
    }

    .modal-component__content {
      background: white;
    }


    &__content {

    }

    &__txt {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $borderBrown;
      margin-bottom: 30px;
    }

    &__row {
      margin-bottom: -15px;
    }

    &__col {

    }

    &__alert {
      font-size: 12px;
      line-height: 14px;
      color: $black;
    }

    &__btn {
      display: flex;
      align-items: center;
    }

    &__btn-i {
      margin-right: 30px;

      @include for-768 {
        margin-left: 20px;
      }

      &:last-child {
        margin-right: 0;

        @include for-768 {
          margin-left: 0
        }
      }
    }
  }
</style>
