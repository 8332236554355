<template>
  <div class="profile-page">
    <div class="profile-page__photo">
      <Photo :largeAvatar="largeAvatar"/>
    </div>
    <div class="profile-page__content">
      <div class="profile-page__content-row">
        <AdditionalFeatures/>
      </div>

      <div class="profile-page__content-row">
        <Information :documentsImage="documentsImage"/>
      </div>
    </div>
  </div>
</template>

<script>
  import Photo from "../Photo/Photo";
  import AdditionalFeatures from "../AdditionalFeatures/AdditionalFeatures";
  import Information from "../Information/Information";

  export default {
    name: "Profile",

    components: {
      Photo,
      AdditionalFeatures,
      Information,
    },

    data() {
      return {

      }
    },

    props: ['largeAvatar', 'documentsImage'],

    watch: {
      largeAvatar: function (val) {
        this.largeAvatar = val
      },

      documentsImage: function (val) {
        this.documentsImage = val
      },
    },

    mounted() {

    },

    metgods: {

    }
  }
</script>

<style lang="scss">
  @import "../../../../../../scss/mixins/mixins";

  .profile-page{
    display: flex;
    margin: 0 -15px;
    padding-top: 30px;

    @include for-1200{
      flex-direction: column;
      align-items: center;
    }

    &__photo{
      max-width: 474px;
      width: 100%;
      padding: 0 15px;


      @include for-1800{
        max-width: 350px;
      }
    }

    &__content{
      width: 100%;
      padding: 0 15px;

      .order-create{
        min-height: initial;
        margin-bottom: 15px;
      }
    }

  }

</style>
