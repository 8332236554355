<template>
	<div class="order-create information">
		<div class="order-create__section">
			<div class="order-create__section-label section-label"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['profile_generalInformtaion'])"></span>
				{{ $t('profile_generalInformtaion.localization_value.value') }}
			</div>

			<div class="order-create__title-horizontal"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<span class="admin-edit" @click="editTranslate(['profile_personalInformation', 'common_edit'])"></span>
				<TitleHorizontal
						:value="$t('profile_personalInformation.localization_value.value')"
						@rightBtnClick="showPersonalEditingPopup"
						:rightBtn="true"
						:rightBtnType="'edit'"
						:rightBtnText="$t('common_edit.localization_value.value')"
				/>
			</div>

			<div class="order-create__row custom-row" v-if="$store.getters.getUserProfile.user_personal_contact">
				<div class="order-create__col custom-col">
					<div class="block-table__row">
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['common_name'])"></span>
								{{$t('common_name.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{$store.getters.getUserProfile.user_personal_contact.first_name}}
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['common_surName'])"></span>
								{{$t('common_surName.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{$store.getters.getUserProfile.user_personal_contact.last_name}}
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['profile_fullName'])"></span>
								{{ $t('profile_fullName.localization_value.value') }}
							</div>
							<div class="block-table__content">
								{{$store.getters.getUserProfile.user_personal_contact.cyrillic_full_name}}
							</div>
						</div>
						<div class="block-table__col block-table__col--33">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['login_email'])"></span>
								{{$t('login_email.localization_value.value')}}
							</div>
							<div class="block-table__content align-items-center">
								<div class="block-table__ico status-ico">
									<img src="/img/common/check.svg" alt="ico">
								</div>
								{{$store.getters.getUserProfile.user_personal_contact.email}}
							</div>
						</div>
					</div>

					<div class="block-table__row">
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['login_password', 'profile_change'])"></span>
								{{$t('login_password.localization_value.value')}}
							</div>
							<div class="block-table__content">
								<span class="link-button text-decoration-underline" @click="showPassEditingPopup">
								{{$t('profile_change.localization_value.value')}}
								</span>
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['login_password', 'profile_change'])"></span>
								{{$t('common_phone.localization_value.value')}}
							</div>
							<div class="block-table__content">
								<span class="link-button text-decoration-underline" @click="changePhonesPopup(true)">
								{{$store.getters.getUserProfile.user_personal_contact.phone}}
								</span>
							</div>
						</div>
						<div class="block-table__col block-table__col--20" v-if="$store.getters.getUserProfile.vat_number">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['profile_vat'])"></span>
								{{$t('profile_vat.localization_value.value')}}
							</div>
							<div class="block-table__content">
								<TooltipTextHelper
												:fullTextWidth="140"
												:text="$store.getters.getUserProfile.vat_number"
												:paragraph="$store.getters.getUserProfile.vat_number"
								/>
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['profile_companyName'])"></span>
								{{$t('profile_companyName.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{$store.getters.getUserProfile.user_personal_contact.company_name}}
							</div>
						</div>
						<div class="block-table__col block-table__col--20" v-if="$store.getters.getUserProfile.user_personal_contact.additional_email">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['profile_aditionalEmail'])"></span>
								{{$t('profile_aditionalEmail.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{$store.getters.getUserProfile.user_personal_contact.additional_email}}
							</div>
						</div>
						<div class="block-table__col block-table__col--20"
								 v-if="$store.getters.getUserProfile.user_personal_contact.birthday"
								 v-bind:class="{'mt-3': $store.getters.getUserProfile.vat_number}"
						>
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['profile_birthday'])"></span>
								{{$t('profile_birthday.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{$store.getters.getUserProfile.user_personal_contact.birthday}}
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="order-create__title-horizontal"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<span class="admin-edit" @click="editTranslate(['common_address', 'common_edit'])"></span>
				<TitleHorizontal
						:value="$t('common_address.localization_value.value')"
						:rightBtn="true"
						:rightBtnType="'edit'"
						:rightBtnText="$t('common_edit.localization_value.value')"
						@rightBtnClick="showAddressEditingPopup"
				/>
			</div>
			<div class="order-create__row custom-row" v-if="$store.getters.getUserProfile.user_personal_contact">
				<div class="order-create__col custom-col">
					<div class="block-table__row">
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['common_address'])"></span>
								{{$t('common_address.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ !$store.getters.getUserProfile.use_alternative_address ? $store.getters.getUserProfile.user_personal_contact.address : $store.getters.getUserProfile.user_alternative_contact.address }}
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['common_city'])"></span>
								{{$t('common_city.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ !$store.getters.getUserProfile.use_alternative_address ? $store.getters.getUserProfile.user_personal_contact.city : $store.getters.getUserProfile.user_alternative_contact.city }}
							</div>
						</div>
						<div class="block-table__col block-table__col--20"
								 v-if="$store.getters.getUserProfile.user_personal_contact.cyrillic_address"
						>
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['common_city'])"></span>
								{{$t('common_cityCyrillic.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{$store.getters.getUserProfile.user_personal_contact.cyrillic_address}}
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['common_regions'])"></span>
								{{$t('common_regions.localization_value.value')}}
							</div>
							<div class="block-table__content">
                <ValueHelper
                    v-if="!$store.getters.getUserProfile.use_alternative_address"
                    :value="$store.getters.getUserProfile.user_personal_contact"
                    :deep="'region.name'"
                />
                <ValueHelper
                    v-else
                    :value="$store.getters.getUserProfile.user_alternative_contact"
                    :deep="'region.name'"
                />
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['common_country'])"></span>
								{{$t('common_country.localization_value.value')}}
							</div>
							<div class="block-table__content">
                <ValueHelper
                    v-if="!$store.getters.getUserProfile.use_alternative_address"
                    :value="$store.getters.getUserProfile.user_personal_contact"
                    :deep="'country.name'"
                />
                <ValueHelper
                    v-else
                    :value="$store.getters.getUserProfile.user_alternative_contact"
                    :deep="'country.name'"
                />
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{
									 	'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
									 	'mt-3': $store.getters.getUserProfile.user_personal_contact.cyrillic_address
									 	}">
								<span class="admin-edit" @click="editTranslate(['common_postNumber'])"></span>
								{{$t('common_postNumber.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ !$store.getters.getUserProfile.use_alternative_address ? $store.getters.getUserProfile.user_personal_contact.zip : $store.getters.getUserProfile.user_alternative_contact.zip }}
							</div>
						</div>
						<div class="block-table__col block-table__col--20 mt-3" v-if="isSNGGroup">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<span class="admin-edit" @click="editTranslate(['common_postNumber'])"></span>
								{{$t('registration_accountItn.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{$store.getters.getUserProfile.itn_number}}
							</div>
						</div>
					</div>
				</div>
			</div>

			<!--<div class="order-create__title-horizontal">-->
				<!--<TitleHorizontal-->
								<!--:value="$t('login_password.localization_value.value')"-->
								<!--:rightBtn="true"-->
								<!--:rightBtnType="'edit'"-->
								<!--:rightBtnText="'Edit'"-->
								<!--@clickEdit="showPassEditingPopup"-->
				<!--/>-->
			<!--</div>-->

			<!--<div class="order-create__row custom-row">-->
				<!--<div class="order-create__col custom-col">-->
					<!--<div class="referal-link">-->
						<!--{{$t('login_password.localization_value.value')}}-->
						<!--<span>&nbsp;&nbsp;********</span>-->
					<!--</div>-->
				<!--</div>-->
			<!--</div>-->


<!--			<div class="order-create__section-label section-label"-->
<!--					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--				<span class="admin-edit" @click="editTranslate(['profile_businessInformtaion'])"></span>-->
<!--				{{$t('profile_businessInformtaion.localization_value.value')}}-->
<!--			</div>-->

<!--			<div class="order-create__title-horizontal">-->
<!--				<TitleHorizontal-->
<!--						:value="$t('profile_referal.localization_value.value')"-->
<!--				/>-->
<!--			</div>-->

<!--			<div class="order-create__row custom-row" v-if="$store.getters.getUserProfile.referral">-->
<!--				<div class="order-create__col custom-col">-->
<!--					<div class="referal-link"-->
<!--							 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--						<span class="admin-edit" @click="editTranslate(['profile_referalLink'])"></span>-->
<!--						{{$t('profile_referalLink.localization_value.value')}}-->

<!--						<span>{{$store.getters.getUserProfile.referral.referralUrl}}</span>-->
<!--					</div>-->
<!--				</div>-->
<!--				<div class="order-create__col custom-col">-->
<!--					<div class="block-table__row">-->
<!--						<div class="block-table__col block-table__col&#45;&#45;20">-->
<!--							<div class="block-table__label"-->
<!--									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--								<span class="admin-edit" @click="editTranslate(['profile_referalCount'])"></span>-->
<!--								{{$t('profile_referalCount.localization_value.value')}}-->
<!--							</div>-->
<!--							<div class="block-table__content">-->
<!--								{{$store.getters.getUserProfile.referral.registeredCount}}-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="block-table__col block-table__col&#45;&#45;20">-->
<!--							<div class="block-table__label"-->
<!--									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--								<span class="admin-edit" @click="editTranslate(['profile_referalVisits'])"></span>-->
<!--								{{$t('profile_referalVisits.localization_value.value')}}-->
<!--							</div>-->
<!--							<div class="block-table__content">-->
<!--								{{$store.getters.getUserProfile.referral.visits_count}}-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="block-table__col block-table__col&#45;&#45;20">-->
<!--							<div class="block-table__label"-->
<!--									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--								<span class="admin-edit" @click="editTranslate(['profile_totalBonuses'])"></span>-->
<!--								{{$t('profile_totalBonuses.localization_value.value')}}-->
<!--							</div>-->
<!--							<div class="block-table__content">-->
<!--								$0 Static-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="block-table__col block-table__col&#45;&#45;20">-->
<!--							<div class="block-table__label"-->
<!--									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--								<span class="admin-edit" @click="editTranslate(['profile_balance'])"></span>-->
<!--								{{$t('profile_balance.localization_value.value')}}-->
<!--							</div>-->
<!--							<div class="block-table__content">-->
<!--								$ {{$store.getters.getUserProfile.balance}}-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->


			<div class="order-create__title-horizontal admin-edit-item">
				<span class="admin-edit" @click="editTranslate(['profile_fullfilment'])"></span>
				<TitleHorizontal
								:value="$t('profile_fullfilment.localization_value.value')"
				/>
			</div>

			<div class="order-create__row custom-row">
				<div class="order-create__col custom-col"
						 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
					<span class="admin-edit" @click="editTranslate(['profile_fulfilmentByAmazon', 'profile_fulfilmentByMerchant', 'profile_sendTracknumberEtsy', 'profile_sendTracknumberAmazon'])"></span>

					<div class="information__wrap-checkbox">
						<div class="information__checkbox">
							<DefaultCheckbox
											:label="$t('profile_fulfilmentByAmazon.localization_value.value')"
											:value="$store.getters.getUserProfile.fulfilment_by_amazon == '1' ? true : false"
											@input="data => changeProfileFulfilment('fulfilment_by_amazon', data)"
							/>
						</div>
						<div class="information__checkbox">
							<DefaultCheckbox
											:label="$t('profile_fulfilmentByMerchant.localization_value.value')"
											:value="$store.getters.getUserProfile.fulfilment_by_merchant == 1 ? true : false"
											@input="data => changeProfileFulfilment('fulfilment_by_merchant', data)"
							/>
						</div>
						<div class="information__checkbox">
							<DefaultCheckbox
											:label="$t('profile_sendTracknumberEtsy.localization_value.value')"
											:value="$store.getters.getUserProfile.user_setting &&
											$store.getters.getUserProfile.user_setting.send_track_number_etsy == '1' ? true : false"
											@input="data => changeProfileFulfilment('send_track_number_etsy', data)"
							/>
						</div>
						<div>
							<DefaultCheckbox
											:label="$t('profile_sendTracknumberAmazon.localization_value.value')"
											:value="$store.getters.getUserProfile.user_setting &&
											$store.getters.getUserProfile.user_setting.send_track_number_amazon == '1' ? true : false"
											@input="data => changeProfileFulfilment('send_track_number_amazon', data)"
							/>
						</div>
					</div>
				</div>
			</div>


			<div class="order-create__title-horizontal"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<span class="admin-edit" @click="editTranslate(['profile_allertManagement'])"></span>
				<TitleHorizontal
								:value="$t('profile_allertManagement.localization_value.value')"
				/>
			</div>

			<div class="order-create__row custom-row">
				<div class="order-create__col custom-col">
					<div class="information__wrap-checkbox">
						<div class="information__checkbox d-flex align-items-center"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['profile_receiveNewTransactions', 'profile_receiveNewTransactionsTooltip'])"></div>
							<DefaultCheckbox
											:label="$t('profile_receiveNewTransactions.localization_value.value')"
											:value="$store.getters.getUserProfile.user_setting &&
											$store.getters.getUserProfile.user_setting.receive_transactions_email == '1' ? true : false"
											@input="data => changeProfileAlerts('receive_transactions_email', data)"
							/>
              <TooltipTextHelper>
                <template slot="text"><TooltipBtn class="tooltip-target"/></template>
                <template slot="title"><span class="white-space-nowrap">{{ $t('profile_receiveNewTransactionsTooltip.localization_value.value') }}</span></template>
              </TooltipTextHelper>
						</div>

						<div class="information__checkbox d-flex align-items-center"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['profile_receiveParcelEmail', 'profile_receiveParcelEmailTooltip'])"></div>
							<DefaultCheckbox
											:label="$t('profile_receiveParcelEmail.localization_value.value')"
											:value="$store.getters.getUserProfile.user_setting &&
											$store.getters.getUserProfile.user_setting.receive_parcel_email == '1' ? true : false"
											@input="data => changeProfileAlerts('receive_parcel_email', data)"
							/>
              <TooltipTextHelper>
                <template slot="text"><TooltipBtn class="tooltip-target"/></template>
                <template slot="title"><span class="white-space-nowrap">{{ $t('profile_receiveParcelEmailTooltip.localization_value.value') }}</span></template>
              </TooltipTextHelper>
						</div>
						<div class="information__checkbox d-flex align-items-center"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['profile_receiveEmailSendingOrders', 'profile_receiveEmailSendingOrdersTooltip'])"></div>
							<DefaultCheckbox
											:label="$t('profile_receiveEmailSendingOrders.localization_value.value')"
											:value="$store.getters.getUserProfile.user_setting &&
											$store.getters.getUserProfile.user_setting.receive_orders_email == '1' ? true : false"
											@input="data => changeProfileAlerts('receive_orders_email', data)"
							/>
              <TooltipTextHelper>
                <template slot="text"><TooltipBtn class="tooltip-target"/></template>
                <template slot="title"><span class="white-space-nowrap">{{ $t('profile_receiveEmailSendingOrdersTooltip.localization_value.value') }}</span></template>
              </TooltipTextHelper>
						</div>
						<div class="information__checkbox d-flex align-items-center"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['profile_receiveOtherMessages', 'profile_receiveOtherMessagesTooltip'])"></div>
							<DefaultCheckbox
											:label="$t('profile_receiveOtherMessages.localization_value.value')"
											:value="$store.getters.getUserProfile.user_setting &&
											$store.getters.getUserProfile.user_setting.receive_systems_email == '1' ? true : false"
											@input="data => changeProfileAlerts('receive_systems_email', data)"
							/>
              <TooltipTextHelper>
                <template slot="text"><TooltipBtn class="tooltip-target"/></template>
                <template slot="title"><span class="white-space-nowrap">{{ $t('profile_receiveOtherMessagesTooltip.localization_value.value') }}</span></template>
              </TooltipTextHelper>
						</div>
						<div class="information__checkbox d-flex align-items-center"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['profile_sendFullInformation'])"></div>
							<DefaultCheckbox
											:label="$t('profile_sendFullInformation.localization_value.value')"
											:value="$store.getters.getUserProfile.user_setting &&
											$store.getters.getUserProfile.user_setting.receive_etsy_information_email === '1' ? true : false"
											@input="data => changeProfileAlerts('receive_etsy_information_email', data)"
							/>
<!--              <TooltipTextHelper>-->
<!--                <template slot="text"><TooltipBtn class="tooltip-target"/></template>-->
<!--                <template slot="title">{{ $t('profile_sendFullInformationTooltip.localization_value.value') }}</template>-->
<!--              </TooltipTextHelper>-->
						</div>
					</div>
				</div>
			</div>

			<!--<div class="order-create__section-label section-label">-->
				<!--{{$t('profile_Identification.localization_value.value')}}-->
			<!--</div>-->



      <div class="order-create__title-horizontal"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['profile_templates'])"></span>
        <TitleHorizontal
            :value="$t('profile_templates.localization_value.value')"
        />
      </div>


      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col">
          <div class="information__wrap-checkbox">
            <div class="information__checkbox"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['profile_orderExpertUserInterface'])"></div>
              <DefaultCheckbox
                  :label="$t('profile_orderExpertUserInterface.localization_value.value')"
                  :value="$store.getters.getUserProfile.user_setting &&
											$store.getters.getUserProfile.user_setting.express_order_template_type == '1' ? true : false"
                  @input="data => changeProfileTemplates('express_order_template_type', data)"
              />
            </div>
          </div>
        </div>
      </div>




			<!-- ***************     Upload Documents    ***************   -->


			<div class="order-create__title-horizontal"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<span class="admin-edit" @click="editTranslate(['profile_uploadDocuments'])"></span>
				<TitleHorizontal
								:value="$t('profile_uploadDocuments.localization_value.value')"
								:rightBtn="true"
								:rightBtnType="'edit'"
								:rightBtnText="$t('common_edit.localization_value.value')"
						@rightBtnClick="showDropFilePopup"
				/>
			</div>

			<div class="custom-row" v-if="Object.keys($store.getters.getUserProfileFiles).length > 0">
				<template v-if="$store.getters.getUserProfileFilesCarPassport && $store.getters.getUserProfileFilesCarPassport.img">
					<template v-for="(driverDoc, index) in $store.getters.getUserProfileFilesCarPassport.img">

						<div class="custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
								 :key="index">
							<div class="site-document">
								<span class="site-document__remove" @click="removeDocument(driverDoc.small.id, index, 'carPassport')">
									<CloseIcon/>
								</span>
								<div class="site-document__img" v-if="documentsPhoto.length > 0">
									<img  v-if="documentsPhoto[_.findKey(documentsPhoto, {'id': driverDoc.small.id})]"
												@click="showSingle(driverDoc.big.id)"
											 :src="
													documentsPhoto[_.findKey(documentsPhoto, {'id': driverDoc.small.id})] ?
													documentsPhoto[_.findKey(documentsPhoto, {'id': driverDoc.small.id})].img :
													null"
											 alt="ico">
									<img v-else
											 src="/img/common/documents-icon.svg" alt="ico">
								</div>
								<div class="site-document__info">
									<div class="site-document__info-row"
											 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
										<span class="admin-edit" @click="editTranslate(['profile_type', 'profile_driveLicense'])"></span>
										{{$t('profile_type.localization_value.value')}}
										<span>{{$t('profile_driveLicense.localization_value.value')}}</span>
									</div>
									<div class="site-document__info-row"
											 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
										<span class="admin-edit" @click="editTranslate(['profile_number'])"></span>
										{{$t('profile_number.localization_value.value')}}
										<span>{{driverDoc.small.data.description}}</span>
									</div>
								</div>
							</div>
						</div>
					</template>
				</template>
				<template v-if="$store.getters.getUserProfileFilesCarPassport && $store.getters.getUserProfileFilesCarPassport.img">
					<template v-for="(driverDoc, index) in $store.getters.getUserProfileFilesCarPassport.pdf">

						<div class="custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
								 :key="index + 'pdf'">
							<div class="site-document">
								<span class="site-document__remove" @click="removeDocument(driverDoc.file.id, index, 'carPassport', 'pdf')">
									<CloseIcon/>
								</span>
								<div class="site-document__img">
<!--									<img @click="openPdf(documentsPhoto[_.findKey(documentsPhoto, {'id': driverDoc.file.id})].img)"-->
									<img @click="openPdfByUUID(driverDoc.file.uuid)"
											 src="/img/common/pdf_file_icon.svg" alt="ico">
								</div>
								<div class="site-document__info">
									<div class="site-document__info-row"
											 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
										<span class="admin-edit" @click="editTranslate(['profile_type', 'profile_driveLicense'])"></span>
										{{$t('profile_type.localization_value.value')}}
										<span>{{$t('profile_driveLicense.localization_value.value')}}</span>
									</div>
									<div class="site-document__info-row"
											 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
										<span class="admin-edit" @click="editTranslate(['profile_number'])"></span>
										{{$t('profile_number.localization_value.value')}}
										<span>{{driverDoc.file.data.description}}</span>
									</div>
								</div>
							</div>
						</div>
					</template>
				</template>

				<template v-if="$store.getters.getUserProfileFilesPassport && $store.getters.getUserProfileFilesPassport.img">
					<template v-for="(passportDoc, index) in $store.getters.getUserProfileFilesPassport.img">
						<div class="custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
								 :key="'passportDoc'+index">

							<div class="site-document">
								<span class="site-document__remove" @click="removeDocument(passportDoc.small.id, index, 'passport')">
									<CloseIcon/>
								</span>
								<div class="site-document__img" v-if="documentsPhoto.length > 0">

									<img v-if="documentsPhoto[_.findKey(documentsPhoto, {'id': passportDoc.small.id})]"
											 @click="showSingle(passportDoc.big.id)"
											 :src="
												documentsPhoto[_.findKey(documentsPhoto, {'id': passportDoc.small.id})] ?
												documentsPhoto[_.findKey(documentsPhoto, {'id': passportDoc.small.id})].img :
												null"
											 alt="ico">
									<img v-else
											 src="/img/common/documents-icon.svg" alt="ico">
								</div>
								<div class="site-document__info">
									<div class="site-document__info-row"
											 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
										<span class="admin-edit" @click="editTranslate(['profile_type', 'profile_passport'])"></span>
										{{$t('profile_type.localization_value.value')}}
										<span>{{$t('profile_passport.localization_value.value')}}</span>
									</div>
									<div class="site-document__info-row"
											 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
										<span class="admin-edit" @click="editTranslate(['profile_number'])"></span>
										{{$t('profile_number.localization_value.value')}}
										<span>{{passportDoc.small.data.description}}</span>
									</div>
								</div>
							</div>
						</div>
					</template>

					<template v-if="$store.getters.getUserProfileFilesPassport && $store.getters.getUserProfileFilesPassport.img">
						<template v-for="(passportDoc, index) in $store.getters.getUserProfileFilesPassport.pdf">

							<div class="custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
									 :key="index + 'pdfPass'">
								<div class="site-document">
								<span class="site-document__remove" @click="removeDocument(passportDoc.file.id, index, 'passport', 'pdf')">
									<CloseIcon/>
								</span>
									<div class="site-document__img">
                    <img @click="openPdfByUUID(passportDoc.file.uuid)"
												 src="/img/common/pdf_file_icon.svg" alt="ico">
									</div>
									<div class="site-document__info">
										<div class="site-document__info-row"
												 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
											<span class="admin-edit" @click="editTranslate(['profile_type', 'profile_passport'])"></span>
											{{$t('profile_type.localization_value.value')}}
											<span>{{$t('profile_passport.localization_value.value')}}</span>
										</div>
										<div class="site-document__info-row"
												 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
											<span class="admin-edit" @click="editTranslate(['profile_number'])"></span>
											{{$t('profile_number.localization_value.value')}}
											<span>{{passportDoc.file.data.description}}</span>
										</div>
									</div>
								</div>
							</div>
						</template>
					</template>


				</template>

				<template v-if="newFile.img.length > 0 || newFile.pdf.length > 0">
						<div class="custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
								 v-for="(doc, index) in newFile.img"
								 :key="'newFile' + index">
							<div class="site-document" >
								<span class="site-document__remove" @click="removeNewDocument(doc.smallImageEntity.id, index)">
										<CloseIcon/>
									</span>
								<div class="site-document__img">
									<img @click="showSingle(doc.bigImageEntity.id)"
													:src="`data:image/${doc.smallImageEntity.extension};base64,` + doc.smallImageEntity.file" alt="ico">
								</div>
								<div class="site-document__info">
									<div class="site-document__info-row"
											 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
										<span class="admin-edit" @click="editTranslate(['profile_type', 'profile_driveLicense'])"></span>
										{{$t('profile_type.localization_value.value')}}
										<span v-if="doc.smallImageEntity.group === 'user_passport_documents'">{{$t('profile_passport.localization_value.value')}}</span>
										<span v-else>{{$t('profile_driveLicense.localization_value.value')}}</span>
									</div>
									<div class="site-document__info-row"
											 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
										<span class="admin-edit" @click="editTranslate(['profile_number'])"></span>
										{{$t('profile_number.localization_value.value')}}
										<span>{{doc.smallImageEntity.data.description}}</span>
									</div>
								</div>
							</div>
						</div>

						<div class="custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
								 v-for="(doc, index) in newFile.pdf"
								 :key="'newFile' + index">
							<div class="site-document" >
								<span class="site-document__remove" @click="removeNewDocument(doc.pdf.id, index, 'pdf')">
										<CloseIcon/>
									</span>
								<div class="site-document__img" @click="openPdfByUUID(doc.pdf.uuid)">
									<img src="/img/common/pdf_file_icon.svg" alt="ico">
								</div>
								<div class="site-document__info">
									<div class="site-document__info-row"
											 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
										<span class="admin-edit" @click="editTranslate(['profile_type', 'profile_driveLicense'])"></span>
										{{$t('profile_type.localization_value.value')}}
										<span v-if="doc.pdf.group === 'user_passport_documents'">{{$t('profile_passport.localization_value.value')}}</span>
										<span v-else>{{$t('profile_driveLicense.localization_value.value')}}</span>
									</div>
									<div class="site-document__info-row"
											 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
										<span class="admin-edit" @click="editTranslate(['profile_number'])"></span>
										{{$t('profile_number.localization_value.value')}}
										<span>{{doc.pdf.data.description}}</span>
									</div>
								</div>
							</div>
						</div>

				</template>

			</div>


			<div class="order-create__title-horizontal"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<span class="admin-edit" @click="editTranslate(['profile_shops'])"></span>
				<TitleHorizontal
						:value="$t('profile_shops.localization_value.value')"
				/>
			</div>


			<div class="order-create__row custom-row">
				<TableShops/>
			</div>

		</div>

		<ChangePassword
				@close="closePassEditingPopup"
				v-if="isModalPassEditingPopup"
		/>

		<PersonalEditing
				@close="closePersonalEditingPopup"
				v-if="isModalPersonalEditingPopup"
		/>

		<AddressEditing
				@close="closeAddressEditingPopup"
				v-if="isModalAddressEditingPopup"
		/>

		<DropFilePopup
				@close="closeDropFilePopup"
				@closeAndAdd="closeDropFilePopupAndAdd"
				v-if="isModalDropFilePopup"
		/>

		<ChangePhonesPopup
						v-if="isModalPhonesPopup"
						:user="userItem"
						@reload="$emit('reload')"
						@close="changePhonesPopup(false)"
		/>

		<!-- all props & events -->
		<Lightbox
						escDisabled
						moveDisabled
						:visible="visible"
						:imgs="imgs"
						:index="index"
						@hide="handleHide"
		></Lightbox>

	</div>
</template>

<script>
	import TitleHorizontal from "../../../../coreComponents/TitleHorizontal/TitleHorizontal";
	import AddressEditing from "../../popups/AddressEditing/AddressEditing.vue";
	import DropFilePopup from "../../popups/DropFilePopup/DropFilePopup";
	import PersonalEditing from "../../popups/PersonalEditing/PersonalEditing.vue";
	import TableShops from "../TableShops/TableShops";
	import ChangePassword from "../../../../coreComponents/Popups/ChangePassword/ChangePassword";
	import Lightbox from 'vue-easy-lightbox'
	import CloseIcon from '../../../../../../public/img/modal-group/close-icon.svg?inline'
	import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
	import {boolToStringFunc} from "../../../../../mixins/commonMixins/boolToStringFunc";
	import ChangePhonesPopup from "../../../../coreComponents/Popups/ChangePhonesPopup/ChangePhonesPopup";
	import TooltipTextHelper from "../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import TooltipBtn from "@/components/UI/tooltips/TooltipBtn/TooltipBtn";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";


	export default {
		name: "Information",
		components:{
      ValueHelper,
      TooltipBtn,
			TooltipTextHelper,
			TitleHorizontal,
			AddressEditing,
			DropFilePopup,
			PersonalEditing,
			ChangePassword,
			TableShops,
			Lightbox,
			CloseIcon,
			DefaultCheckbox,
			ChangePhonesPopup
		},

		mixins: [boolToStringFunc],

		props: ['documentsImage'],

		data(){
			return {
				isModalAddressEditingPopup: false,
				isModalPersonalEditingPopup: false,
				isModalPassEditingPopup: false,
				isModalDropFilePopup: false,
				isModalPhonesPopup: false,
				documentsPhoto: [],
				newFile: {
					img: [],
					pdf: [],
				},

				imgs: [], // Img Url , string or Array of string
				visible: false,
				index: 0, // default: 0

				userItem: {}
			}
		},


		watch: {
			documentsImage: function (val) {
				this.documentsPhoto = val
			},
		},

		mounted() {

		},

		methods: {

			showSingle(id) {

				this.$store.dispatch('getFileFromServer', id).then((response) => {

					// this.imgs = `data:image/png;base64,` + response[id]
					// or
					this.imgs = []
					this.imgs.push({
						title: 'img',
						src: `data:image/png;base64,` + response[id]
					})
					this.show()
				})

			},


			changeProfileFulfilment(type, val) {
				let profile = this.$store.getters.getUserProfile

        let oldData = {
					fulfilment_by_amazon: profile.fulfilment_by_amazon,
					fulfilment_by_merchant: profile.fulfilment_by_merchant,
				}

        if(profile.user_setting){
					oldData.send_track_number_etsy = profile.user_setting.send_track_number_etsy
					oldData.send_track_number_amazon = profile.user_setting.send_track_number_amazon
					oldData.receive_etsy_information_email = profile.user_setting.receive_etsy_information_email
				} else {
					oldData.send_track_number_etsy = '0'
					oldData.send_track_number_amazon = '0'
					oldData.receive_etsy_information_email = '0'
				}

        // convert bool into either '1' or '0' string to get rid of the "boolToStringFunc" function and it's bugs
				oldData[type] = val ? '1' : '0'

				let data = {
					"userData" : {
						"fulfilment_by_merchant" : oldData.fulfilment_by_merchant,
						"fulfilment_by_amazon" : oldData.fulfilment_by_amazon
					},
					"userSettings" : {
						"send_track_number_etsy" : oldData.send_track_number_etsy,
						"send_track_number_amazon" : oldData.send_track_number_amazon,
						"receive_etsy_information_email" : oldData.receive_etsy_information_email
					}
				}

				this.$store.dispatch('changeProfileFulfilment', {id: profile.id, data: data}).then(() => {
          //update data manually to fix backend sending old values
          if (type === "fulfilment_by_merchant" || type === "fulfilment_by_amazon") {
            profile[type] = data['userData'][type]
          }
          else {
            profile.user_setting[type] = data['userSettings'][type]
          }

          this.$store.commit('setUserProfile', {userProfile: profile})
					this.openNotify('success', 'common_changeApplied')
				})
			},

			changeProfileAlerts(type, val) {
				let profile = this.$store.getters.getUserProfile
				let oldData = {}

				if(profile.user_setting) {
					oldData = {
						receive_transactions_email: profile.user_setting.receive_transactions_email,
						receive_parcel_email: profile.user_setting.receive_parcel_email,
						receive_orders_email: profile.user_setting.receive_orders_email,
						receive_systems_email: profile.user_setting.receive_systems_email,
						receive_etsy_information_email: profile.user_setting.receive_etsy_information_email,
					}
				} else {
					oldData = {
						receive_transactions_email: '0',
						receive_parcel_email: '0',
						receive_orders_email: '0',
						receive_systems_email: '0',
						receive_etsy_information_email: '0',
					}
				}

				oldData[type] = val

				let data = {
					"receive_transactions_email" : this.boolToStringFunc(oldData.receive_transactions_email, ['1', true], '1', '0'),
					"receive_parcel_email" : this.boolToStringFunc(oldData.receive_parcel_email, ['1', true], '1', '0'),
					"receive_orders_email" : this.boolToStringFunc(oldData.receive_orders_email, ['1', true], '1', '0'),
					"receive_systems_email" : this.boolToStringFunc(oldData.receive_systems_email, ['1', true], '1', '0'),
					"receive_etsy_information_email" : this.boolToStringFunc(oldData.receive_etsy_information_email, ['1', true], '1', '0'),
				}

				this.$store.dispatch('changeProfileAlerts', {id: profile.id, data: data}).then((response) => {
					profile.user_setting = response.data.data.user_setting
					this.openNotify('success', 'common_changeApplied')
				})
			},

      changeProfileTemplates(type, val) {
        let profile = this.$store.getters.getUserProfile
        let oldData = {}

        if(profile.user_setting) {
          oldData = {
            express_order_template_type: profile.user_setting.express_order_template_type,
          }
        } else {
          oldData = {
            express_order_template_type: '0',
          }
        }

        oldData[type] = val

        let data = {
          "express_order_template_type" : this.boolToStringFunc(oldData.express_order_template_type, ['1', true], '1', '0'),
        }

        this.$store.dispatch('changeProfileTemplates', {id: profile.id, data: data}).then((response) => {
          profile.user_setting = response.data.data.user_setting
          this.openNotify('success', 'common_changeApplied')
        })
      },

			show() {
				this.visible = true
			},

			handleHide() {
				this.visible = false
			},

			// Phone
			changePhonesPopup(val){
				if(val){
					this.$store.dispatch('getUserManageItem', this.$store.getters.getUserProfile.id).then(response => {
						this.userItem = this.getRespData(response)
						this.isModalPhonesPopup = val
					})
					return
				}
				this.isModalPhonesPopup = val
			},

			// Password
			showPassEditingPopup(){
				this.isModalPassEditingPopup = true
			},

			closePassEditingPopup() {
				this.isModalPassEditingPopup = false
			},

			// Personal
			showPersonalEditingPopup(){
				this.isModalPersonalEditingPopup = true
			},

			closePersonalEditingPopup() {
				this.isModalPersonalEditingPopup = false
			},

			// Address
			showAddressEditingPopup() {
				this.isModalAddressEditingPopup = true
			},

			closeAddressEditingPopup() {
				this.isModalAddressEditingPopup = false
			},

			// Dropfiles
			showDropFilePopup() {
				this.isModalDropFilePopup = true
			},

			closeDropFilePopup() {
				this.isModalDropFilePopup = false
			},

			closeDropFilePopupAndAdd(newFile, close) {
				if(close) this.isModalDropFilePopup = false

				if(newFile) {
					this.newFile.img = this.newFile.img.concat(newFile.img)
					this.newFile.pdf = this.newFile.pdf.concat(newFile.pdf)
				}
			},

			removeNewDocument(id, index, pdf = false) {
				this.$store.dispatch('removeUserDocument', id).then(() => {

					if(pdf){
						this.newFile.pdf.splice(index, 1)
					} else {
						this.newFile.img.splice(index, 1)
					}

				})
			},

			removeDocument(id, index, type, pdf = false) {
				this.$store.dispatch('removeUserDocument', id).then(() => {
					let car = this.$store.getters.getUserProfileFilesCarPassport
					let passport = this.$store.getters.getUserProfileFilesPassport

					console.log(car);

					if(type === 'carPassport') {
						if(pdf){
							car.pdf.splice(index, 1)
						} else {
							car.img.splice(index, 1)
						}
						this.$store.commit('setUserProfileFilesCarPassport', {userProfileFilesCarPassport: car})
					} else {
						if(pdf){
							passport.pdf.splice(index, 1)
						} else {
							passport.img.splice(index, 1)
						}
						this.$store.commit('setUserProfileFilesPassport', {userProfileFilesPassport: passport})
					}


				})
			},

			openPdf(base64) {
				this.globalOpenPdfBase64(base64)
			},

      openPdfByUUID(uuid) {
        let routeData = this.$router.resolve({
          path: this.$store.getters.GET_PATHS.getPdfFile + '/' + uuid
        })

        window.open(routeData.href, '_blank');
			},
		}
	}
</script>

<style lang="scss">
	@import "../../../../../scss/mixins/mixins";

	.information{
		@include for-1600{
			padding: 24px 15px 24px 15px;
		}

		&__checkbox{
			margin-bottom: 10px;
		}
	}
</style>
