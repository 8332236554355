<template>
  <modal
      @close="checkClose"
      class="drop-file--modal drop-file"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                          'profile_SelectFile',
                        ])"></div>
        {{ $t('profile_SelectFile.localization_value.value') }}
      </div>
    </template>
    <template slot="body">



      <div class="drop-file__content">

        <div class="drop-file__row custom-row">
          <div class="drop-file__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                          'profile_number',
                        ])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': validation.number}"
                    :error="validation.number"
                    :errorTxt="serverError ? validationTxt.number : $t(`${validationTranslate.number}.localization_value.value`)"
                    :label="$t('profile_number.localization_value.value')"
                    :type="'text'"
                    v-model="number"
                    @onEnter="submitForm"
            />
          </div>

          <div class="drop-file__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                          'profile_DocumentType',
                        ])"></div>
            <DefaultSelect
                    v-bind:class="{'ui-no-valid': validation.selectedType}"
                    :options="optionType"
                    :error="validation.selectedType"
                    :errorTxt="serverError ? validationTxt.selectedType : $t(`${validationTranslate.selectedType}.localization_value.value`)"
                    :label="$t('profile_DocumentType.localization_value.value')"
                    @change="changeType"
                    :optionsLabel="'value'"
                    :selected="selectedType.value"
            />
          </div>
        </div>

        <DropZone
                :parentFiles="files"
                :maxSize="'3'"
                @changeImg="changeImg"/>
      </div>
    </template>
    <template slot="footer">
      <div class="drop-file__btn d-flex justify-content-between align-center">
        <div
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                          'common_cancel',
                        ])"></div>
          <span
              class="site-link site-link--alt drop-file__btn-i"
              @click="checkClose"
          >
            {{$t('common_cancel.localization_value.value')}}
          </span>
        </div>

        <div
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                          'profile_uploadDocuments',
                        ])"></div>
          <MainButton
              :value="$t('profile_uploadDocuments.localization_value.value')"
              class="drop-file__btn-i wfc"
              v-on:click.native="submitForm"
              v-bind:class="{'disabled-btn' : $store.getters.getProfileBtn}"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>


  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DropZone from "../../../../coreComponents/DropZone/DropZone";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {validation} from "../../../../../services/validation";
  import {checkAccess} from "../../../../../mixins/userCredentionalMixins/userCredentional";
  import {mapGetters} from "vuex";

  export default {
    name: "DropFilePopup",
    components: {
      Modal,
      MainButton,
      DropZone,
      DefaultInput,
      DefaultSelect,
    },

    mixins: [checkAccess],

    data() {
      return {
        number: '',
        selectedType: {},
        optionType: [],
        files: [],

        fileArray: {
          img: [],
          pdf: []
        },

        serverError: false,

        validation: {
          number: false,
          optionType: false,
        },

        validationTranslate: {
          number: 'default',
          optionType: 'default',
        },

        validationTxt: {
          number: false,
          optionType: false,
        },

      }
    },

    computed: {
      ...mapGetters([
        'getUserProfile',
        'GET_ERRORS',
      ])
    },

    mounted() {
      this.$store.dispatch('fetchUserDocumentTypes').then((response) => {
        // console.log(response);
        this.optionType = response.data.data.fileGroups
      })
    },

    methods: {

      checkClose() {
        if(this.fileArray.img.length > 0 || this.fileArray.pdf.length > 0){
          this.$emit('closeAndAdd', this.fileArray, true)
        } else {
          this.$emit('close')
        }
      },

      changeImg(files) {
        this.files = files
      },

      changeType(val) {
        this.selectedType = val;
      },

      submitForm() {
        let validationItems = {
          number: this.number,
          selectedType: this.selectedType.name !== undefined ? this.selectedType.name : '',
        }

        let validationOptions = {
          number: {
            type: [
              'empty',
            ]
          },
          selectedType: {
            type: [
              'empty',
            ]
          },
        };

        let validate = validation(validationItems, validationOptions);
        this.validation = validate.validation;

        this.validationTranslate = validate.validationError

        if(validate.isValidate && this.files.length > 0) {
          this.serverError = false
          let formDataArray = []


          this.files.map((item) => {
            let reader = new FormData();
            reader.append("file", item.file);
            reader.append("description", this.number);
            reader.append("group", this.selectedType.name);
            formDataArray.push(reader)
          })

          this.sendFiles(formDataArray, 0)
        }

      },

      sendFiles(formDataArray, index) {

        //   IF LAST ITERATIONS
        if(formDataArray.length - 1 === index) {

          // IF MAX SIZE ERROR
          if(this.files[index].maxSizeError) return

          // IF FILE WILL BE LOADED
          if(this.files[index].success === true){
            this.$emit('closeAndAdd', this.fileArray, true)
            this.openNotify('success', 'common_notificationFilesAdded')
            return
          }

          this.$store.dispatch('uploadUserDocument', {id: this.getUserProfile.id, data: formDataArray[index]}).then((response) => {

            if(this._.has(response, 'data') && response.data.status === this.GET_ERRORS.SUCCESS_CODE){

              if(Object.hasOwnProperty.call(response.data.data, 'pdf')) {
                this.fileArray.pdf.push(response.data.data)
              } else {
                this.fileArray.img.push(response.data.data)
              }

              this.files[index].success = true
              this.$emit('closeAndAdd', this.fileArray, true)
              this.openNotify('success', 'common_notificationFilesAdded')

            } else if(response.response.status === this.GET_ERRORS.VALIDATION_ERROR_CODE) {
              let errors = response.response.data.errors
              this.files[index].error = true
              this.files[index].errorTxt = errors
              // this.$emit('closeAndAdd', this.fileArray, true)
            }
          })
        } else {

          // IF FILE WILL BE LOADED || IF MAX SIZE ERROR
          if(this.files[index].success === true || this.files[index].maxSizeError){
            this.sendFiles(formDataArray, index + 1)
            return
          }


          this.$store.dispatch('uploadUserDocument', {id: this.getUserProfile.id, data: formDataArray[index]}).then((response) => {
            if (this._.has(response, 'data') && response.data.status === this.GET_ERRORS.SUCCESS_CODE) {

              if(Object.hasOwnProperty.call(response.data.data, 'pdf')) {
                this.fileArray.pdf.push(response.data.data)
              } else {
                this.fileArray.img.push(response.data.data)
              }
              this.files[index].success = true
              this.sendFiles(formDataArray, index + 1)
            } else if (response.response.status === this.GET_ERRORS.VALIDATION_ERROR_CODE) {
              let errors = response.response.data.errors
              this.files[index].error = true
              this.files[index].errorTxt = errors
              this.sendFiles(formDataArray, index + 1)
            } else if(response.response.status === this.GET_ERRORS.FORBIDDEN_CODE) {
              this.checkAccess2faAt()
            }
          })
        }

      }

    }

  }
</script>

<style lang="scss">
  @import "../../../../../scss/mixins/mixins";


  .drop-file {

    .modal-component__inner {
      max-width: 740px;
    }

    .modal-component__content {
      background: white;
    }
  }
</style>
